export const mycareerMock = {
	data: {
		message: '활동 조회가 정상적으로 이루어졌습니다.',
		data: {
			프로젝트: [
				{
					id: 25,
					category: {
						categoryId: 2,
						categoryKoName: '프로젝트',
						categoryEnName: 'PROJECT',
					},
					name: 'qweqweqwe',
					alias: 'qweqwe',
					unknown: false,
					summary: null,
					startdate: '2024-10-02',
					enddate: '2025-03-29',
					teamSize: 0,
					isTeam: false,
					contribution: 0,
					location: 'ON_CAMPUS',
					detailList: null,
				},
			],
			교육: [
				{
					id: 19,
					category: {
						categoryId: 3,
						categoryKoName: '교육',
						categoryEnName: 'EDU',
					},
					name: '111',
					alias: '111',
					unknown: true,
					summary: null,
					startdate: '2025-01-29',
					enddate: '2025-03-18',
					organizer: '111',
					time: 1,
					detailList: null,
				},
				{
					id: 18,
					category: {
						categoryId: 3,
						categoryKoName: '교육',
						categoryEnName: 'EDU',
					},
					name: 'qweqweqweqweqwe',
					alias: 'qweqweqwe',
					unknown: false,
					summary: null,
					startdate: '2024-01-04',
					enddate: '2024-04-30',
					organizer: 'qwe',
					time: 0,
					detailList: null,
				},
			],
			기타: [],
			대외활동: [
				{
					id: 31,
					category: {
						categoryId: 1,
						categoryKoName: '대외활동',
						categoryEnName: 'ACTIVITY',
					},
					name: '123123123123123',
					alias: '123',
					unknown: true,
					summary: null,
					startdate: '2025-03-06',
					enddate: '2025-03-18',
					organizer: '123123',
					role: '',
					teamSize: 0,
					contribution: 0,
					isTeam: false,
					detailList: null,
				},
				{
					id: 29,
					category: {
						categoryId: 1,
						categoryKoName: '대외활동',
						categoryEnName: 'ACTIVITY',
					},
					name: 'ㅁㄴㅇㅁㄴㅇ',
					alias: 'ㅁㄴㅇㅁㄴㅇ',
					unknown: true,
					summary: null,
					startdate: '2025-01-30',
					enddate: '2025-03-18',
					organizer: 'ㅁㄴㅇㅁㄴㅇ',
					role: '',
					teamSize: 0,
					contribution: 0,
					isTeam: false,
					detailList: null,
				},
			],
			공모전대회: [],
			동아리: [
				{
					id: 58,
					category: {
						categoryId: 5,
						categoryKoName: '동아리',
						categoryEnName: 'CIRCLE',
					},
					name: 'skala 1기',
					alias: 'skala',
					unknown: false,
					summary: '스칼라 1기',
					startdate: '2025-03-02',
					enddate: '2025-03-21',
					location: true,
					role: '',
					detailList: null,
				},
				{
					id: 51,
					category: {
						categoryId: 5,
						categoryKoName: '동아리',
						categoryEnName: 'CIRCLE',
					},
					name: 'asdasdasd',
					alias: 'asdasd',
					unknown: false,
					summary: null,
					startdate: '2025-03-01',
					enddate: '2025-03-05',
					location: true,
					role: '',
					detailList: null,
				},
				{
					id: 52,
					category: {
						categoryId: 5,
						categoryKoName: '동아리',
						categoryEnName: 'CIRCLE',
					},
					name: 'qweqweqwe',
					alias: 'qweqwe',
					unknown: false,
					summary: null,
					startdate: '2024-12-04',
					enddate: '2025-03-04',
					location: false,
					role: '',
					detailList: null,
				},
			],
			경력: [],
		},
	},
};
