//pages/Apply/ApplySchedule, Intro/Select
//공고 추가 모달
import React, { useState } from 'react';
import styled from 'styled-components';
import ModalTagBox from '../Apply/ModalTagBox';  // 일관성 있게 사용
import { createRecruit } from '../../api/Apply/Recruit';
import { trackEvent } from '../../utils/ga4'; 
 
const ModalBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

const ModalContent = styled.div`
	background: white;
	padding: 20px;
	border-radius: 10px;
	width: 850px;
	height: 700px;
	max-width: 90%;
	position: relative;
	align-items: center;
		z-index: 2000;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: 1px;
	font-size: 3.3em;
	color: #999;
	cursor: pointer;
`;

const ModalTitle = styled.h2`
	margin-top: 30px;
	margin-bottom: 10px;
	font-size: 1.5em;
	margin-left: 100px;
	font-family: 'Bold';
	font-size: 24px;
`;

const Divider = styled.div`
	width: 650px;
	height: 6px;
	background-color: #ccc;
	margin-bottom: 20px;
	margin: 0 auto 20px;
`;

const Label = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	margin-left: 100px;
	margin-top: 20px;
	font-family: 'ExtraLight';
	font-size: 18px;

	&::after {
		content: ' *';
		color: #fc5555;
	}
`;

const LabelStart = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	margin-left: 90px;
	margin-top: 10px;
	font-family: 'ExtraLight';
	font-size: 18px;

	&::after {
		content: ' *';
		color: #fc5555;
	}
`;

const LabelEnd = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	margin-right: 70px;
	margin-top: 10px;
	font-family: 'ExtraLight';
	font-size: 18px;

	&::after {
		content: ' *';
		color: #fc5555;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Input = styled.input`
	width: 640px;
	padding: 12px;
	margin-bottom: 15px;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	background: #f5f5f5;
	font-size: 1em;
	margin-top: 10px;
	height: 25px;
	font-family: 'ExtraLight';
	font-size: 16px;
	outline: none;
`;

const InputDateStart = styled.input`
	width: 300px;
	padding: 12px;
	height: 25px;
	margin-bottom: 10px;
	margin-left: 85px;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	background: #f5f5f5;
	font-size: 1em;
	margin-top: -10px;
	font-family: 'ExtraLight';
	font-size: 16px;
	outline: none;
`;

const InputDateEnd = styled.input`
	width: 300px;
	height: 25px;
	padding: 12px;
	margin-bottom: 15px;
	margin-right: 75px;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	background: #f5f5f5;
	font-size: 1em;
	margin-top: -10px;
	font-family: 'ExtraLight';
	font-size: 16px;
	outline: none;
`;

const InputWrapperStart = styled.div`
	margin-top: 30px;
`;

const InputWrapperEnd = styled.div`
	margin-top: 30px;
`;

const Row = styled.div`
	display: flex;
	justify-content: center;
	gap: 0px;
	width: 100%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: -20px;
`;

const SaveButton = styled.button`
	width: 640px;
	height: 50px;
	background-color: #3aaf85;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 11px;
	cursor: pointer;
	font-size: 1em;
	margin-top: 10px;
	margin: 20px auto 0 auto;
	margin-left: 105px;
	font-family: 'ExtraLight';
	font-size: 18px;
`;

const LabelTag = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	margin-left: 96px;
	margin-top: 5px;
	font-family: 'ExtraLight';
	font-size: 18px;

	&::after {
		content: ' *';
		color: #fc5555;
	}
`;

const LabelLink = styled.label`
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
	margin-left: 95px;
	margin-top: 25px;
	font-family: 'ExtraLight';
	font-size: 18px;
`;

const InputWrapperTag = styled.div`
	margin-top: 20px;
	margin-left: 90px;
`;

const InputWrapperLink = styled.div`
	margin-top: 10px;
	margin-left: 90px;
	font-family: 'ExtraLight';
	font-size: 16px;
`;

const TagBoxWrapper = styled.div`
	margin-top: 20px;
`;

const ErrorMessage = styled.p`
	color: red;
	font-size: 0.9em;
	margin-top: 10px;
	text-align: center;
	font-family: 'Light';
	font-size: 16px;
`;

const FieldWrapper = styled.div`
	margin-bottom: 10px;
`;

const LabelContainer = styled.div`
	margin-top: 10px;
`;

const AddApplyModal = ({ onClose, onSave }) => {
	const [title, setTitle] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [tags, setTags] = useState([]); //  선택된 태그 목록
	const [link, setLink] = useState('');
	const [status, setStatus] = useState('unapplied');
	const [errorMessages, setErrorMessages] = useState({
		title: '',
		startTime: '',
		endTime: '',
		endTimeOrder: '',
		link: '',
	});

	const handleTitleChange = (e) => {
		const value = e.target.value.slice(0, 20); // 20자까지만 허용
		setTitle(value);
	};
	

	const isValidUrl = (url) => {
		if (url.length > 2048) return false; // URL 길이 제한 추가 (선택 사항)
	
		const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*(\?[;&a-zA-Z0-9%_.~+=-]*)?$/;
		return urlPattern.test(url);
	};
	

	const handleSave = async () => {
		let errors = { title: '', startTime: '', endTime: '', endTimeOrder: '', link: '' };

		if (!title) {
			errors.title = '공고 제목을 입력해주세요.';
		}
		if (!startTime) {
			errors.startTime = '시작 날짜를 선택해주세요.';
		}
		if (!endTime) {
			errors.endTime = '종료 날짜를 선택해주세요.';
		}
		if (startTime && endTime && new Date(endTime) <= new Date(startTime)) {
			errors.endTimeOrder = '종료 날짜는 시작 날짜 이후로 설정해주세요.';
		}
		if (link && !isValidUrl(link)) {
			errors.link = '올바른 형식의 링크를 입력해주세요.';
		}

		// 에러 메시지 업데이트
		setErrorMessages(errors);

		// 하나라도 에러가 있으면 저장 중단
		if (errors.title || errors.startTime || errors.endTime || errors.endTimeOrder || errors.link) {
			return;
		}

		trackEvent('add_confirm', {
			category: 'apply',
			detail: 'add_recruit',
			action_type: 'confirm',
			label: '확인',
		});

		const formatDateTime = (dateTime) => {
			const date = new Date(dateTime);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}`;
		};

		const formattedStartTime = formatDateTime(startTime);
		const formattedEndTime = formatDateTime(endTime);

		const recruitData = {
			title,
			startTime: formattedStartTime,
			endTime: formattedEndTime,
			status,
			tags, //  선택한 태그만 서버로 전송
			link,
		};

		try {
			const response = await createRecruit(recruitData);

			if (response && response.id) {
				onSave(response.id);
				onClose();
			} else {
				alert('공고 생성에 실패했습니다.');
			}
		} catch (error) {
			alert('공고 생성에 실패했습니다.');
		}
	};

	// 태그 변경 시 즉시 업데이트
	const handleTagListChange = (newTags) => {
		setTags(newTags);
	};

	return (
		<ModalBackdrop>
			<ModalContent>
				<CloseButton onClick={onClose}>×</CloseButton>
				<ModalTitle>새로운 공고 추가</ModalTitle>
				<Divider />
				<FieldWrapper>
					<Label>공고 제목</Label>
					<InputWrapper>
						<Input
							type="text"
							placeholder="공고 제목을 작성하세요 (20자 이하)"
							value={title}
							onChange={handleTitleChange}
							maxLength={20} 
						/>
					</InputWrapper>
				</FieldWrapper>
				<Row>
					<InputWrapper>
						<FieldWrapper>
							<LabelStart>접수 시작 일시</LabelStart>
							<InputWrapperStart>
								<InputDateStart
									type="datetime-local"
									value={startTime}
									onChange={(e) => setStartTime(e.target.value)}
								/>
							</InputWrapperStart>
						</FieldWrapper>
					</InputWrapper>
					<InputWrapper>
						<FieldWrapper>
							<LabelEnd>접수 마감 일시</LabelEnd>
							<InputWrapperEnd>
								<InputDateEnd type="datetime-local" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
							</InputWrapperEnd>
						</FieldWrapper>
					</InputWrapper>
				</Row>
				<FieldWrapper>
					<LabelTag>태그</LabelTag>
					<InputWrapperTag>
						<TagBoxWrapper>
						<ModalTagBox onTagListChange={handleTagListChange} initialTags={tags} isWhiteBackground={true} />
						</TagBoxWrapper>
					</InputWrapperTag>
				</FieldWrapper>
				<FieldWrapper>
					<LabelContainer>
						<LabelLink>링크</LabelLink>
					</LabelContainer>
					<InputWrapperLink>
						<Input
							type="text"
							placeholder="공고 혹은 접수 페이지 링크를 입력하세요"
							value={link}
							onChange={(e) => setLink(e.target.value)}
						/>
					</InputWrapperLink>
					{errorMessages.title && <ErrorMessage>{errorMessages.title}</ErrorMessage>}
					{errorMessages.startTime && <ErrorMessage>{errorMessages.startTime}</ErrorMessage>}
					{errorMessages.endTime && <ErrorMessage>{errorMessages.endTime}</ErrorMessage>}
							{errorMessages.endTimeOrder && <ErrorMessage>{errorMessages.endTimeOrder}</ErrorMessage>}
							{errorMessages.link && <ErrorMessage>{errorMessages.link}</ErrorMessage>}
				</FieldWrapper>
				<ButtonWrapper>
					<SaveButton onClick={handleSave}>확인</SaveButton>
				</ButtonWrapper>
			</ModalContent>
		</ModalBackdrop>
	);
};

export default AddApplyModal;